var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vlt-header-r-sec vlt-search",on:{"click":function($event){$event.stopPropagation();}}},[_c('form',{staticClass:"main-form",on:{"submit":function($event){$event.preventDefault();return _vm.searchSubmitCB($event)}}},[(_vm.displayFullSearch)?_c('div',{staticClass:"input-ico"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"searchElem",staticClass:"search",attrs:{"type":"text","placeholder":"Search"},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value},_vm.searchInputCB]}}),_vm._m(0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.floatSearch && _vm.showResults && _vm.searchQuery && _vm.searchQuery.length > 2
          ),expression:"\n            !floatSearch && showResults && searchQuery && searchQuery.length > 2\n          "}],staticClass:"results",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"box"},[(_vm.results)?_c('ul',_vm._l((_vm.results),function(result){return _c('li',{key:result.contentid,on:{"click":function($event){return _vm.setQuery(result.title)}}},[_vm._v(" "+_vm._s(result.title)+" ")])}),0):_vm._e(),(_vm.localSearch)?_c('p',[_vm._v("Previously Searched...")]):_vm._e(),(_vm.localSearch)?_c('ul',_vm._l((_vm.localSearch),function(result){return _c('li',{key:result.contentid,staticClass:"previous-seach",on:{"click":function($event){return _vm.setQuery(result.title)}}},[_vm._v(" "+_vm._s(result.title)+" ")])}),0):_vm._e()])])]):_vm._e(),(_vm.displaySearch)?_c('div',{staticClass:"search-image"},[_c('img',{attrs:{"src":require("@/assets/icons/float-search.svg"),"alt":"search-ico","loading":"lazy"},on:{"click":_vm.actFloatSearch}})]):_vm._e()]),(_vm.floatSearch)?_c('searchFloater',{attrs:{"closePopupCB":function () {
        this$1.floatSearch = false;
      },"showResults":_vm.showResults,"searchQuery":_vm.searchQuery,"searchInputCB":_vm.searchInputCB,"localSearch":_vm.localSearch,"results":_vm.results,"updateQuery":function (val) {
        this$1.searchQuery = val;
      },"setQuery":_vm.setQuery,"searchSubmitCB":_vm.searchSubmitCB}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn-search",attrs:{"type":"submit"}},[_c('img',{attrs:{"src":require("@/assets/icons/search.svg"),"alt":"search-ico","loading":"lazy"}})])}]

export { render, staticRenderFns }