<template>
  <div class="vlt-header-r-sec vlt-search" @click.stop>
    <form class="main-form" @submit.prevent="searchSubmitCB">
      <div class="input-ico" v-if="displayFullSearch">
        <input
          type="text"
          placeholder="Search"
          class="search"
          @input="searchInputCB"
          v-model="searchQuery"
          ref="searchElem"
        />
        <button type="submit" class="btn-search">
          <img
            src="@/assets/icons/search.svg"
            alt="search-ico"
            loading="lazy"
          />
        </button>

          <div
            class="results"
            v-show="
              !floatSearch && showResults && searchQuery && searchQuery.length > 2
            "
            @click.stop
          >
            <div class="box">
              <ul v-if="results">
                <li
                  v-for="result in results"
                  :key="result.contentid"
                  @click="setQuery(result.title)"
                >
                  {{ result.title }}
                </li>
              </ul>
              <p v-if="localSearch">Previously Searched...</p>
              <ul v-if="localSearch">
                <li
                  v-for="result in localSearch"
                  :key="result.contentid"
                  @click="setQuery(result.title)"
                  class="previous-seach"
                >
                  {{ result.title }}
                </li>
              </ul>
            </div>
          </div>

      </div>

      <div v-if="displaySearch" class="search-image">
        <img
          src="@/assets/icons/float-search.svg"
          alt="search-ico"
          @click="actFloatSearch"
          loading="lazy"
        />
      </div>
    </form>


    <searchFloater
      v-if="floatSearch"
      :closePopupCB="
        () => {
          this.floatSearch = false;
        }
      "
      :showResults="showResults"
      :searchQuery="searchQuery"
      :searchInputCB="searchInputCB"
      :localSearch="localSearch"
      :results="results"
      :updateQuery="
        (val) => {
          this.searchQuery = val;
        }
      "
      :setQuery="setQuery"
      :searchSubmitCB="searchSubmitCB"
    />
  </div>
</template>

<script>
import { EventBus } from "@/main";
import { mapGetters, mapActions } from "vuex";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import appMixins from "@/mixins/appMixins";

export default {
  name: "search",
  data() {
    return {
      searchQuery: "",
      showResults: false,
      results: undefined,
      localSearch: undefined,
      displayFullSearch: null,
      displaySearch: null,
      flagAutologin: true,
      floatSearch: false,
      eventFlag: true,
    };
  },
  watch: {
    searchQuery(val) {
      if (!val) {
        this.showResults = false;
        document.body.removeEventListener("click", this.closeDropdown);
        this.eventFlag = true;
      }
      if (val && this.eventFlag) {
        document.body.addEventListener("click", this.closeDropdown);
        this.eventFlag = false;
      }
    },
  },
  methods: {
    ...mapActions(["actListContents"]),
    actFloatSearch() {
      this.floatSearch = true;
    },
    searchInputCB() {
      let query = this.searchQuery;

      if (query && query.length > 2) {
        let params = {
          query,
        };
        if (localStorage.getItem("localSearch")) {
          this.showResults = true;
        }

        this.actListContents(params)
          .then((response) => {
            // If no items were there, result will come as null.
            if (!(response && response.length)) return;

            if (this.inputSubmitted) return;

            // Else render the list
            this.results = response;

            this.showResults = true;
          })
          .catch((error) => {
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.searchInputCB);
                this.flagAutologin = false;
                return;
              }
            }
          });
      } else {
        this.showResults = false;
      }
    },
    searchSubmitCB() {
      let query = this.searchQuery;
      if (query && query.length > 2) {
        let getSearch = JSON.parse(localStorage.getItem("localSearch"));
        let dataObj = {};
        dataObj.title = this.searchQuery;
        if (getSearch) {
          // console.log("length ", Object.keys(getSearch).length);
          for (const [key, value] of Object.entries(getSearch)) {
            if (value.title == this.searchQuery) {
              getSearch.splice(key, 1);
            }
          }

          getSearch.splice(0, 0, dataObj);

          if (Object.keys(getSearch).length > 5) {
            getSearch.pop();
          }
        } else {
          getSearch = [{ title: this.searchQuery }];
        }

        localStorage.setItem("localSearch", JSON.stringify(getSearch));

        //GA
        if (this.searchQuery) {
          let GA = {
            Keyword: this.searchQuery,
          };
          this.ContentSearchEvent(GA);
        }

        this.setSearchFromLocal();
        this.showResults = false;
        this.$router.push({
          name: "SearchList",
          params: { query: this.searchQuery, result: this.results },
        });
        this.searchQuery = "";
      }
    },

    setQuery(query) {
      this.searchQuery = query;
      this.searchSubmitCB();
    },
    setSearchFromLocal() {
      if (localStorage.getItem("localSearch")) {
        this.localSearch = JSON.parse(localStorage.getItem("localSearch"));
      }
    },
    closeDropdown(e) {
      if (e.target !== this.$refs.searchElem) {
        this.searchQuery = "";
        this.showResults = false;
      }
    },
    setSearchBar(){
        if (screen.width >= 768) {
              this.displayFullSearch = true;
              this.displaySearch = false;
          } 
        else {
              this.displayFullSearch = false;
              this.displaySearch = true;
          }
    }
  },
  mounted() {
    this.setSearchBar();
    this.setSearchFromLocal();
    window.addEventListener('resize',this.setSearchBar);
    EventBus.$on("SearchResult", (result) => {
      if (result && result.length) {
        if (this.inputSubmitted) return;

        // Else render the list
        if (this.searchQuery) {
          this.results = result;
          // Show the result list
          this.showResults = true;
        } else {
          // this.hideResults();
          this.showResults = false;
        }
      }
    });
  },
  beforeDestroy(){
    window.removeEventListener('resize',this.setSearchBar);
  },
  components: {
    searchFloater: () => import("@/components/Popups/searchFloat.vue"),
  },
  mixins: [GoogleAnalytics, appMixins],
};
</script>

<style lang="scss" scoped>
// @import "~sass/modules/components";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";

.vlt-search {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  position: relative;
  height:100%;
  .main-form{
    height:100%;
    display:flex;
    flex-flow:row nowrap;
    align-items: center;
  }
  .search {
    color: $clr-white;
    font-size: 1rem;
    padding: 8px 25px 8px 5px;
    font-family: $font-family;
    // margin-right: 20px;
    padding-left: 15px;
    display: block;
    // width: 200px;
    width: 22.75rem;
    height: 100%;
    border: none;
    border-radius: 24px;
    transition: all 0.5s;
    background: $clr-bg-gray-light-5 0% 0% no-repeat padding-box;

    @include inputFocus;
    &:focus::placeholder {
      opacity: 0.8;
    }
  }
  .search::placeholder {
    color: $clr-white;
    opacity: 0.5;
  }
  .input-ico {
    position: relative;
    height:$header-right-height; // setting height of input...
    .btn-search {
      display:block;
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 50%;
      height:50%;
      transform:translateY(-50%);
      right: 10px;
      cursor: pointer;
      img {
        display: block;
        height:100%;
      }
      &:hover {
        transform: translateY(-50%) scale(1.05);
      }
      &:active {
        transform: translateY(-50%) scale(0.9);
      }
    }
  }
  .results {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    // @extend .primary-bg.inverted;
    box-shadow: 0px 0px 6px $clr-bg-gray-light-6;
    z-index: 998;
    background-color: $clr-bg-black;
    .box {
      max-height: 180px;
      overflow-y: auto;
      position: relative;
      ul {
        padding: 2px 5px;
        cursor: pointer;
        li {
          // &:last-child {
          //   border: 0;
          // }
          padding: 3% 2%;
          a {
            padding: 8px 0;
            display: block;
            color: $clr-white-1;
          }
          &.previous-seach{
            opacity:0.6;
          }
          &:hover {
            opacity:1;
            color: $font-clr-white;
          }
        }
      }
      p {
        padding: 9px;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 78px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 997;
    background-color: transparent;
  }
  .search-image {
    img {
      height: $header-icons-height + 1px;
      display: block;
      cursor: pointer;
    }
  }
}

@include breakpoint(max768) {
  // .vlt-search {
  //   position: relative;
  //   .search {
  //     width: 160px;
  //   }
  // }
}

@include breakpoint(max600) {
  // .vlt-search {
  //   .search {
  //     width: 100px;
  //   }
  // }
  .input-ico {
    .btn-search {
      right: 6px;
    }
  }
}
</style>